import { initForms } from './components/initForms';
import { initJivosite } from './components/initJivosite';
import { initHashEmail } from './components/initHashEmail';
import { initLazyLoadFonts } from './components/initLazyLoadFonts';
import { initHashMessage } from './components/initHashMessage';
import { initAutoresizeTextarea } from './components/initAutoresizeTextarea';
import { initScrollLocker } from '../../../../Common/Resources/src_front/js/components/initScrollLocker';
import { initEmailAutocompleteDropdown } from '../../../../Common/Resources/templates/components/EmailAutocompleteDropdown/initEmailAutocompleteDropdown';
import { initRippleAnimation } from '../../../../Common/Resources/templates/components/RippleAnimation/initRippleAnimation';

window.addEventListener('load', handleOnLoadPage, false);

function handleOnLoadPage() {
    initLazyLoadFonts();
    initAutoresizeTextarea();
    initScrollLocker();
    initForms();
    initJivosite();
    initHashEmail();
    initHashMessage();
    initEmailAutocompleteDropdown();
    initRippleAnimation();
}
